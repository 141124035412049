<template>
    <div class="settings-content">
        <div class="settings-header">
            <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                    <a href="#" @click.prevent="$router.push({ name: 'dashboard' })" class="back-to-dashboard-btn">
                        < Back To Dashboard
                    </a>
                    <div class="settings-links-wrapper">
                        <router-link class="setting-link" :to="{ name: 'settings.locations' }">Locations</router-link>
                        <router-link class="setting-link" :to="{ name: 'settings.billing' }">Billing</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-2">
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingsLayout'
}
</script>

<style lang="scss">
.settings-content {
    .settings-header {
        padding: 18px 0;
        background-color: white;
        box-shadow: 0px 5px 8px rgba(70, 78, 95, 0.02);

        .back-to-dashboard-btn {
            display: inline-flex;
            flex-direction: row;
            color: #80808F;
            background-color: #F5F8FA;
            padding: 12px 20px;
            font-weight: 500;
            font-size: 15px;
            border-radius: 6px;
        }
    }

    .settings-links-wrapper {
        display: flex;
        align-items: center;

        .setting-link:last-child {
            margin-right: 0;
        }
    }

    .setting-link {
        display: inline-block;
        padding: 12px 18px;
        border-radius: 6px;
        color: #80808F;
        font-weight: 600;
        margin-right: 9px;

        &.router-link-active {
            background-color: #F5F8FA;
            color: #009EF6;
        }
    }
}
</style>
